export class MobileMenu {
  constructor(el) {
    this.el = el;
    this.el.forEach( el => {
      el.onclick = this.openMenu;
      let selector = el.getAttribute('data-target');
      document.querySelector(`.${selector} .close`).onclick = this.closeMenu;

    });
  }
  openMenu(){
    let selector = this.getAttribute('data-target');
    document.querySelector(`.${selector}`).classList.add('open');
  }
  closeMenu(){
    this.parentNode.classList.remove('open');
  }
}
