/**
 *  @author Eugene Terentev <eugene@terentev.net>
 */
// 'use strict'

// let Intense = require('./instanse.js');
// require('./components/jquery');
let linemenu = require('../bower/linemenu/js/classie');
import Galery from './components/galery';
require('./components/simplelightbox/dist/simple-lightbox.min');
// require('./components/nanogalery');

// require('./components/masonry.pkgd.min');
//
// require('./components/imagesloaded.pkgd.min');
// require('./components/classie');
// require('./components/main');
// require('./components/grid');
let Intense = require('./instanse.js');

// import './components/modernizr-custom';
// import './components/imagesloaded.pkgd.min';
// import './components/masonry.pkgd.min';
// import './components/classie';
// import './components/main';
// import './components/grid';
import { MobileMenu } from './MobileMenu';
// const nano = new nanoGalery (document.querySelector('#nanogallery2'));
// console.warn(nano);
const galery = new Galery({items: document.querySelectorAll('.grid__item')});
$('.menu__item').click(function() {
  $('.menu__list li').removeClass('menu__item--current');
  $(this).addClass('menu__item--current');
});

let elements = document.querySelectorAll('.intense');
if (elements.length > 0) {
  Intense(elements);
}

let mobileContact = new MobileMenu(document.querySelectorAll('[data-toggle="mobile-menu"]'));

$(document).ready(() => {
  var $gallery = $('.gallery_default a').simpleLightbox();
  $('[data-toggle="tooltip"]').tooltip();
  $(".hover").mouseleave(
    function () {
      $(this).removeClass("hover");
    }
  );
});
