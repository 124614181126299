const animationArray = ['slideInUp', 'slideInDown', 'slideInLeft'];
const getRandomAnimation = () => {
  const randomItem = Math.round(Math.random() * (animationArray.length - 1));
  console.warn(animationArray, randomItem);
  return animationArray[randomItem];
};

export default class Galery {
  constructor({ items }) {
    if (!items) return;
    this.items = items;
    this.items.forEach(el => el.addEventListener('click', (e) => this.showItem((e))));
    this.galeryGrid = document.querySelector('.grid__galery');
  }

  showItem(e) {
    // console.log()
    // e.stopImmediatePropagation();
    this.galeryGrid.classList.add('grid__galey__opened');
    this.itmGalery = e.currentTarget;
    const closeBtns = this.itmGalery.querySelectorAll('.close_galery');
    this.initGalery();
    this.itmGalery.classList.add('show__item');
    // closeBtns.forEach(closeBtn => closeBtn.addEventListener('click', (e) => this.closeItem(e, itmGalery)));
    // closeBtns.forEach(closeBtn => closeBtn.addEventListener('click', (e) => this.closeItem(e, itmGalery)));
    closeBtns.forEach(closeBtn => this.eventsLiestener(closeBtn, ['click']));
    document.onkeydown = (evt) => {
      evt = evt || window.event;
      if (evt.keyCode == 27) {
        this.closeItem(evt, this.itmGalery);
      }
    };
  };

  eventsLiestener(itm, events) {
    events.forEach(event => itm.addEventListener(event, (e) => this.closeItem(e, this.itmGalery)));
  }

  initGalery() {
    const generalImg = this.itmGalery.querySelector('.gradient img');
    const galeries = this.itmGalery.querySelectorAll('.galery img');
    galeries && galeries.forEach(img => img.addEventListener('click', this.imgGaleryClick(img, generalImg, galeries)));
  }

  imgGaleryClick(img, generalImg, galeries) {
    return (e) => {
      e.stopPropagation();
      console.warn(e);
      galeries.forEach(img => img.removeAttribute('class'));
      img.classList.add('active');
      generalImg.className = '';
      generalImg.classList.add('animated');
      generalImg.classList.add('slideOutUp');
      setTimeout(function () {
        generalImg.className = '';
        generalImg.classList.add('animated');
        generalImg.classList.add(getRandomAnimation());
        generalImg.setAttribute('src', img.getAttribute('src'));

      }, 1000);
    };
  }

  closeItem(e, itmGalery) {
    console.warn(e);
    e.stopPropagation();
    itmGalery.classList.remove('show__item');
    this.galeryGrid.classList.remove('grid__galey__opened');
  }
}
